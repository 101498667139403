
.blog__date__txt{
  color: rgb(77, 77, 77);
  font-size: 14px ;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog__readmore__btn {
  text-decoration: none;
  padding: 2px 10px;
  transition: all 0.3s;
  font-weight: bolder;
  border: 2px solid var(--green);
  color: var(--green);
}
.blog__readmore__btn:hover {
  background-color: var(--green);
  color: var(--white);
}
.blogCard__img__box{
  position: relative;
}
.blogCard__img__box .card__badge{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--bodybg);
}
.card__badge p{
  color: var(--green);
}
.card__badge .txt__sm{
  font-size: 14px;
}
.categoryHeading__box{
  border-bottom: 1px solid var(--green);
}
.categoryCard__wrapper{
  overflow-x: scroll;
  display: flex;
  padding: 20px 0px;
}
.categoryCard__wrapper::-webkit-scrollbar {
  display: none !important;
}
.category__card{
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 20px;
}
@media only screen and (max-width: 576px) {
  .category__card{
    flex-basis: 240px;
  }    
}