/* product page products list */

.category__page__heading{
position: relative;
z-index: 1;
}
.category__page__heading::before{
content: "";
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  height: 8px ;
  background-color: rgba(115, 255, 0, 0.4);
}

/* product page products card */

.category__page__card{
background-color: var(--white);
}
.category__card__imgbg{
background-color: var(--cardImgBg);
display: flex;
align-items: center;
justify-content: center;
}
.category__card__badge{
 display: flex;
 flex-wrap: wrap;
}
.category__card__badge span{
padding: 2px 5px ;
background-color: var(--dark);
margin-right: 5px;
font-size: 12px ;
font-weight: bolder;
color: var(--white);
border-radius: 3px;
}
.price__txt{
color: rgb(39, 134, 243);
}
.category__card__btn {
display: inline-block;
text-decoration: none;
padding: 2px 10px;
transition: all 0.3s;
font-weight: bolder;
border: 2px solid var(--green);
color: var(--green);
}
.category__card__btn:hover {
background-color: var(--green);
color: var(--white);
}