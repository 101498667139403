
:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

.container {
  margin: 0 auto;
}

.swiper_container {
  position: relative;
}

.swiper-slide {
  width: 30rem;
  height: 20rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .swiper-slide {
    width: 28rem !important;
    height: 14rem !important;
  }
  .swiper-slide img {
    width: 12rem !important;
    height: 12rem !important;
  }
}

.swiper-slide img {
  width: 14rem;
  height: 14rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
}

.slider-controler .swiper-button-next {
  left: 60% !important;
  transform: translateX(-60%) !important;
  /* border: 2px solid black; */
  
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 90% !important;
    transform: translateX(-90%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 10% !important;
    transform: translateX(-10%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 40%;
  transform: translateX(-40%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 1.5rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 100% !important;
  bottom: 1rem;
  margin-top: 15px;
  /* border: 2px solid greenyellow; */
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}