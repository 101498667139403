  .left-animated-container {
    position: relative;
    overflow: hidden; /* Ensures the animation stays within the div */
    /* background-color: #f4fded; */
    /* background-color: white; */
  }
  
  .left-animated-image {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 28%; /* Start from outside the left edge */
    transform: translateY(-50%); /* Center the image vertically */
    animation: leftToRight 2s ease-out forwards, pulses 2s 2s forwards; /* Animation for sliding in and pulsing */
  }
  
  @keyframes leftToRight {
    0% {
      transform: translateY(-50%) translateX(-100%); /* Start from outside the left edge */
      opacity: 0;
    }
    100% {
      transform: translateY(-50%) translateX(28%); /* Move to final position */
      opacity: 1;
    }
  }
  
  @keyframes pulses {
    0% {
      transform: translateY(-50%) translateX(28%) scale(1);
    }
    100% {
      transform: translateY(-50%) translateX(28%) scale(1.50);
    }
  }
  
  .left-animated-image img {
    width: 200px; /* Adjust to the size of your image */
    height: auto;
    display: block;
    border-radius: 10px; /* Add rounded corners */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  }
  

  @media only screen and (max-width: 768px) {
    .left-animated-image img {
      width: 100px; /* Adjust to the size of your image */
    }
  }


  /* ----------------------------------------------------------------------------------------------- */

  .right-animated-container {
    position: relative;
    overflow: hidden; /* Ensures the animation stays within the div */
    /* background-color: #f0f0f0;  */
    background-color: white;
  }

  
  
  .right-animated-image {
    position: absolute;
    top: 50%; /* Center vertically */
    right: -150px; /* Start from outside the right edge */
    transform: translateY(-50%); /* Center the image vertically */
    animation: slideIn 2s ease-out forwards, pulse 2s 2s forwards; /* Animation for sliding in and pulsing */
  }

  @media only screen and (max-width: 768px) {
    .right-animated-image {
      right: 10%;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(-50%) translateX(150px); /* Start from outside the right edge */
      opacity: 0;
    }
    100% {
      transform: translateY(-50%) translateX(calc(-28vw)); /* Move to final position */
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: translateY(-50%) translateX(calc(-28vw)) scale(1);
    }
    100% {
      transform: translateY(-50%) translateX(calc(-28vw)) scale(1.50);
    }
  }
  
  .right-animated-image img {
    width: 280px; /* Adjust to the size of your image */
    height: auto;
    display: block;
    border-radius: 10px; /* Add rounded corners */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  }

  @media only screen and (max-width: 768px) {
    .right-animated-image img {
      width: 100px; /* Adjust to the size of your image */
    }
  }

  /* BG Image =---------------------------------------------------= */
  .thirdBgImageApply {
    background-image: url("../../Assets/Images/MagicUI/Product/bg1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 2px solid red; */
  }

  @media only screen and (max-width: 768px) {
    .thirdBgImageApply {
      background-image: url("../../Assets/Images/MagicUI/Product/bg5.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      /* border: 2px solid red; */
    }
  }

  .fifthBgImageApply {
    background-image: url("../../Assets/Images/MagicUI/Product/bg3.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 2px solid red; */
  }

  @media only screen and (max-width: 768px) {
    .fifthBgImageApply {
      background-image: url("../../Assets/Images/MagicUI/Product/bg6.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      /* border: 2px solid red; */
    }
  }

  @media only screen and (max-width: 768px) {
      .splitBg{
        background-color: white;
      }
  }












  