:root {
    --dark: rgb(22, 18, 18);
    --shadow__color : rgb(85 0 170 / 10%);
    --white: #ffffff;
    --darkwhite: #dddddd;
    --green: #6ba229;
    --greendark: #497415;
    --bodybg : rgb(244, 253, 237);
    --cardImgBg : rgb(232, 255, 213);
    /* ------------- */
    --topnavHeight: 40px;
    --mainnavHeight: 70px;
    --catogeryNavHeight : 50px ;
    font-size: 16px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--dark);
}
a{
    color: var(--dark);
}
html{
    scroll-behavior: smooth;
}
body {
    font-family: 'Baloo Paaji 2', cursive;
    overflow-x: hidden;
    background-color: var(--bodybg);
}

@tailwind base;
@tailwind components;
@tailwind utilities;