
:root {
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
  }
  
  .industryContainer {
    margin: 0 auto;
    width: 100%;
  }
  
  .industry_swiper_container {
    position: relative;
  }
  
  .industry_swiper_slide {
    width: 25rem;
    height: 15rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 500px) {
    .industry_swiper_slide {
      width: 20rem !important;
      height: 10rem !important;
    }
    .industry_swiper_slide div img {
      width: 8rem !important;
      height: 8rem !important;
    }
  }
  
  .industry_swiper_slide div img {
    width: 10rem;
    height: 10rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .industry-slider-controler {
    position: relative;
    margin-top: 45px;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .industry-slider-controler .swiper-button-next {
    left: 75% !important;
    transform: translateX(-75%) !important;
  }
  
  @media (max-width: 990px) {
    .industry-slider-controler .swiper-button-next {
      left: 90% !important;
      transform: translateX(-90%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .industry-slider-controler .swiper-button-next {
      left: 90% !important;
      transform: translateX(-90%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .industry-slider-controler .swiper-button-prev {
      left: 10% !important;
      transform: translateX(-10%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .industry-slider-controler .swiper-button-prev {
      left: 10% !important;
      transform: translateX(-10%) !important;
    }
  }
  
  .industry-slider-controler .slider-arrow {
    background: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left: 25%;
    transform: translateX(-25%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .industry-slider-controler .slider-arrow ion-icon {
    font-size: 1.5rem;
    color: #222224;
  }
  
  .industry-slider-controler .slider-arrow::after {
    content: '';
  }
  
  .industry-swiper-pagination {
    position: relative;
    width: 100% !important;
    bottom: 1rem;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .industry-swiper-pagination .industry-swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .industry-swiper-pagination .industry-swiper-pagination-bullet-active {
    background: var(--primary);
  }