
:root {
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
  }
  
  .productContainer {
    margin: 0 auto;
  }
  
  .product_swiper_container {
    position: relative;
  }
  
  .product_swiper_slide {
    position: relative;
  }

  .product_container {
    width: 75%;
    height: auto;
  }
  
  @media (max-width: 500px) {
    .product_container {
      width: 70%;
    }
    .product_swiper_slide div div img {
      width: 8rem !important;
      height: 8rem !important;
    }
  }
  
  .product_swiper_slide div div img {
    width: 10rem;
    height: 10rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .product-slider-controler {
    position: relative;
    margin-top: 40px;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .product-slider-controler .swiper-button-next {
    left: 75% !important;
    transform: translateX(-75%) !important;
  }
  
  @media (max-width: 990px) {
    .product-slider-controler .swiper-button-next {
      left: 90% !important;
      transform: translateX(-90%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .product-slider-controler .swiper-button-next {
      left: 90% !important;
      transform: translateX(-90%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .product-slider-controler .swiper-button-prev {
      left: 10% !important;
      transform: translateX(-10%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .product-slider-controler .swiper-button-prev {
      left: 10% !important;
      transform: translateX(-10%) !important;
    }
  }
  
  .product-slider-controler .slider-arrow {
    background: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left: 25%;
    transform: translateX(-25%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .product-slider-controler .slider-arrow ion-icon {
    font-size: 1.5rem;
    color: #222224;
  }
  
  .product-slider-controler .slider-arrow::after {
    content: '';
  }
  
  .swiper-pagination {
    position: relative;
    width: 100% !important;
    bottom: 1rem;
    margin-top: 15px;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  }